import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

/**
 * @typedef {Object} Options
 * @property {'phone'|'tablet'|'mobile'|boolean|Function} disable condition to disable animation
 * @property {string} [startEvent='DOMContentLoaded'] name of the event that AOS should initialize on
 * @property {string} [initClassName='aos-init'] class applied after initializatio
 * @property {string} [animatedClassName='aos-animate'] class applied on animation
 * @property {boolean} [useClassNames=false] whether to add content of "data-aos" as classes on scroll
 * @property {number} [offset=120] offset (in px) fromt he original trigger point
 * @property {number} [delay=0] animation delay
 * @property {number} [duration=400] animation duration
 * @property {string} [easing='ease'] animation easing function name
 * @property {boolean} [once=false] whether animation should happen only once - while scrolling down
 * @property {boolean} [mirror=false] whether elements should animate out while scrolling past them
 * @property {AnchorPlacement} [anchorPlacement='top-bottom'] defines which position of the element regarding to window should trigger the animation
 */

/**
 * Anchor Placement
 *
 * ```
 *                   ┌―――――┐
 *           ┌―――――┐ │ B-T │
 * ┌―╒═════╕─│ C-T │─╘═════╛─┐
 * │ │ T-T │ └―――――┘         │
 * │ └―――――┘                 │
 * │                 ┌―――――┐ │
 * │         ┌―――――┐ │ B-C │ │
 * ├―╒═════╕─│ C-C │─╘═════╛─┤
 * │ │ T-C │ └―――――┘         │
 * │ └―――――┘                 │
 * │                 ┌―――――┐ │
 * │         ┌―――――┐ │ B-B │ │
 * └―╒═════╕─│ C-B │─╘═════╛─┘
 *   │ T-B │ └―――――┘
 *   └―――――┘
 * ```
 *
 * @typedef {(
 *   'top-bottom' |
 *   'top-center' |
 *   'top-top' |
 *   'center-bottom' |
 *   'center-center' |
 *   'center-top' |
 *   'bottom-bottom' |
 *   'bottom-center' |
 *   'bottom-top'
 * )} AnchorPlacement
 */

/**
 * Default options
 * @type {Options}
 */
const defaultOptions = {
  // Global settings:
  disable: false,
  startEvent: "DOMContentLoaded",
  initClassName: "aos-init",
  animatedClassName: "aos-animate",
  useClassNames: false,

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120,
  delay: 0,
  duration: 400,
  easing: "ease",
  once: false,
  mirror: false,
  anchorPlacement: "top-bottom",
};

class AOS {
  /**
   * @param {Options} options
   * @returns {AOS}
   */
  static init(options = {}) {
    return new AOS(options);
  }

  /**
   * Starts an AOS instance
   * @param {Options} options
   */
  constructor(options = {}) {
    const {
      anchorPlacement,
      animatedClassName,
      delay,
      duration,
      easing,
      initClassName,
      offset: globalOffset,
      once: globalOnce,
      useClassNames,
    } = this.options = { ...defaultOptions, ...options };

    /**
     * Set global settings on body, based on options
     * so CSS can use it
     */
    const body = document.querySelector(`body`);
    body.setAttribute(`data-aos-delay`, delay);
    body.setAttribute(`data-aos-duration`, duration);
    body.setAttribute(`data-aos-easing`, easing);

    // start observing your elements
    document.querySelectorAll(`[data-aos]`).forEach((target) => {
      if (target.classList.contains(animatedClassName)) {
        return;
      }

      target.classList.add(initClassName);

      const customClassNames = useClassNames && target.getAttribute("data-aos");
      const animatedClassNames = [animatedClassName]
        .concat(customClassNames ? customClassNames.split(" ") : [])
        .filter((className) => typeof className === "string");

      /**
       * @type {[string, string]}
       */
      const [anchorSide, rootSide] =
        (target.getAttribute("data-aos-anchor-placement") || anchorPlacement)
          .split("-");

      const anchor = target.getAttribute("data-aos-anchor");

      const once = (target.getAttribute("data-aos-once") || `${globalOnce}`) === "true";
      const offset = Number(target.getAttribute("data-aos-offset")) || globalOffset;
      const direction = target.getAttribute("data-aos-direction");

      ScrollTrigger.create({
        trigger: (anchor && document.querySelector(anchor)) || target,
        start: `${anchorSide} ${rootSide}-=${offset}px`,
        end: "bottom top",
        // endTrigger: '#otherID',
        once,
        onEnter(self) {
          target.classList.add(...animatedClassNames);
          fireEvent(`aos:in`, target);
        },
        onLeave(self) {
          if (!once && direction !== "forward" ) {
            target.classList.remove(...animatedClassNames);
            fireEvent(`aos:out`, target);
          }
        },
        onLeaveBack(self) {
          if (!once) {
            target.classList.remove(...animatedClassNames);
            fireEvent(`aos:out`, target);
          }
        }
      });
    });
  }
}

export default AOS;

/**
 * Fires a custom event
 * @param {string} eventName
 * @param {CustomEventInit} details
 * @returns
 */
const fireEvent = (eventName, detail) => {
  const customEvent = new CustomEvent(eventName, {
    bubbles: true,
    detail,
  });

  return document.dispatchEvent(customEvent);
};
