/**
 * Queries for a single element with selector
 * @param {string} selector
 * @param {Document|Element} [parent=document]
 * @returns {Element}
 */
globalThis.$ = (selector, parent = document) => parent.querySelector(selector);

/**
 * Queries for a node list with selector
 * @param {string} selector
 * @param {Document|Element} [parent=document]
 * @returns {NodeList}
 */
globalThis.$$ = (selector, parent = document) =>
  parent.querySelectorAll(selector);
