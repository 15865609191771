const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const columnSections = $$(".two-column-default");

columnSections.forEach(function(section) {
  const columns = $$(".column:nth-child(even)", section);

  columns.forEach(function(column) {
    if (!$("h1,h2,h3,h4,h5,h6", column)) {
      const previousSibling = column.previousElementSibling;
      const previousPosition = getComputedStyle(previousSibling).getPropertyValue("--position");
      const currentPosition = getComputedStyle(column).getPropertyValue("--position");

      column.style.setProperty("--mobile-position", previousPosition);
      previousSibling.style.setProperty("--mobile-position", currentPosition);
    }
  });
});
