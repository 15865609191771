document.addEventListener("DOMContentLoaded", function() {
  const resultsDiv = document.getElementById("searchResults");
  if (!resultsDiv) return;

  const key = resultsDiv.getAttribute("data-key");
  const { searchParams } = new URL(location.href);
  searchParams.set("s", "tamintl");

  fetch(`https://search.sam-cms.com?${searchParams}`)
  .then(function (response) {
    return response.text();
  })
  .then(function(html) {
    resultsDiv.innerHTML = html;
  })
  .catch(function (err) {
    console?.log(err);
  });
});
