class SnapSlider extends HTMLElement {
  index = 0;

  connectedCallback() {
    const id = this.getAttribute(`data-snap-slider`);

    const {
      children,
      childElementCount: count,
    } = this;

    const slides = Array.from(children);

    const nav = document.querySelector(`[data-snap-slider-nav="${id}"]`);
    const prev = nav?.querySelector(`[data-snap-slider-goto="prev"]`);
    const next = nav?.querySelector(`[data-snap-slider-goto="next"]`);
    // The first dot is a template.
    const dotTemplate = nav?.querySelector(`[data-snap-slider-goto="1"]`);
    dotTemplate?.remove();

    const dots = [];

    prev?.addEventListener("click", () => {
      this.goto(this.index - 1, "prev");
    });
    next?.addEventListener("click", () => {
      this.goto(this.index + 1, "next");
    });

    // Observes when slide(s) enters the slider's viewport to handle
    // navigation and index.
    const observer = new IntersectionObserver((entries) => {
      // The slider can show multiple slides at once, but we are only
      // interested of the first and last entries to see if they are
      // the first and/or last slide.
      const {0: first, length, [length-1]: last} = entries;

      // When the first entry is interesecting the slider's viewport,
      // it's visible.
      if (first.isIntersecting) {
        // We set the slider's index to its index.
        this.index = slides.indexOf(first.target);
        const isFirst = this.index === 0;
        // Toggles the "Previous" button.
        prev?.toggleAttribute("disabled", isFirst);

        this.update();
      }

      // When the last entry is interesecting the slider's viewport,
      // it's visible.
      if (last.isIntersecting) {
        const isLast = slides.indexOf(last.target) === count - 1;
        // Toggles the "Next" button.
        next?.toggleAttribute("disabled", isLast);

        this.update();
      };

      dots.forEach((dot, index) => {
        dot.classList.toggle("active", index === this.index);
      });
    }, {
      root: this,
      threshold: 0.99,
    });

    slides.forEach((slide, index) => {
      observer.observe(slide);

      if (dotTemplate) {
        const dot = dotTemplate.cloneNode(true);
        dot.setAttribute("data-snap-slider-goto", index + 1);
        dot.innerText = index + 1;
        dot.addEventListener("click", () => {
          this.goto(index);
        });
        next?.before(dot);
        dots.push(dot);
      }
    });

    this.update();
  }

  /**
   * Slides to a specific slide by its index.
   * @param {number} index Index of the slide to go to
   * @returns {number} The new active slide index
   */
  goto(index = this.index, direction = "next") {
    let {
      children,
      childElementCount: count,
    } = this;

    index = this.index = Math.clamp(index, 0, count - 1);
    let inline = "start";
    if (direction === "prev") {
      inline = "end";
    }

    requestAnimationFrame(() => {
      children[index]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: inline,
      });

      this.update();
    });

    return index;
  }

  update() {
    let {
      children,
      childElementCount: count,
      index,
    } = this;

    while (count--) {
      const active = count === index;
      const child = children[count];
      child.classList.toggle("active", active);
      if (active) {
        child.removeAttribute("aria-hidden");
      } else {
        child.setAttribute("aria-hidden", "true");
      }
    }
  }
}

customElements.define("snap-slider", SnapSlider);
