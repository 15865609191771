import "./$.js";
import AOS from "./aos.js";
import "./imports.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

AOS.init({
  duration: 3000,
  easing: "ease-in-out",
  useClassNames: true, // So we can use Tailwind utilities when animated.
});

const documentElement = document.documentElement;

// Polyfills `anchor` attribute.
if (!("anchorElement" in documentElement.style)) {
  function polyfill(element) {
    let anchorName = element.getAttribute("anchor");
    const anchor = document.getElementById(anchorName);
    element.anchorElement = anchor;

    if (anchor) {
      anchorName = `--${anchorName}`;
      anchor.style.anchorName = anchorName;
      element.style.positionAnchor = anchorName;
    } else {
      element.style.positionAnchor = "";
    }
  }

  const targets = document.querySelectorAll(`[anchor]`);
  const observer = new MutationObserver(([mutation]) => {
    polyfill(mutation.target);
  });
  targets.forEach((element) => {
    polyfill(element);

    observer.observe(element, {
      attributeFilter: ["anchor"],
    });
  });
}

/**
 * Sets actual value of 1vw in pixels without scrollbar.
 *
 * When using `50vw`, it includes the scrollbar width, affecting our
 * calculations. We use ResizeObserver to set the actual value of 1vw
 * in pixels to the property `--vw`.
 *
 * When vw unit is needed, use `var(--vw, 1vw) * X` instead of `Xvw`.
 */
new ResizeObserver(() => {
  const vw = documentElement.clientWidth / 100;
  documentElement.style.setProperty("--spacing-vw", `${vw}px`);
}).observe(document.body);

$$("video[autoplay], video[muted].play-on-enter").forEach(async (/** @type HTMLVideoElement */ video) => {
  const { classList } = video;
  video.controls = false;
  try {
    await video.play();
  } catch (_error) {

  }

  video.setAttribute("playsinline", "playsinline");

  classList.toggle("playing", !video.paused);
  classList.toggle("paused", video.paused);

  video.addEventListener("click", () => {
    video.paused ? video.play() : video.pause();
    classList.toggle("playing", !video.paused);
    classList.toggle("paused", video.paused);
  });
});

$$("video[muted].play-on-enter").forEach((video) => {
  ScrollTrigger.create({
    trigger: video,
    start: `top bottom`,
    end: "bottom top",
    once: true,
    onEnter(self) {
      video.setAttribute("autoplay", "autoplay");
      video.play();
      video.classList.add("playing");
      video.classList.remove("paused");
    }
  });
});

// Polyfills `Math.clamp`
if (typeof Math.clamp !== "function") {
  Math.clamp = function(x, lower, upper) {
    return Math.min(Math.max(x, lower), upper);
  }
}

/**
 * Switches popovers containing chunk to normal dialogs.
 *
 * When in popover mode or modal, the element is in top layer, above
 * the contextual menu in SAM. This prevents users to right click on
 * the chunk to edit.
 *
 * Therefore, we intercept the `beforetoggle` event and switch to
 * normal dialog mode with `show()` method.
 *
 * Similarly, if a popover is positioned absolute, it will be converted
 * to normal dialog, as popover can't be absoluted.
 */
document.querySelectorAll("dialog[popover] .SAMChunkWrapper, dialog[popover].absolute").forEach((popover) => {
  popover = popover.closest("dialog[popover]");
  const id = popover.id;
  // Converts the close button in popover to a submit button.
  const toggle = popover.querySelector(`button[popovertarget="${id}"]`);
  toggle.addEventListener("click", (event) => {
    event.preventDefault();
    popover.close();
  });

  popover.addEventListener("beforetoggle", (/** @type {ToggleEvent} */ event) => {
    const { newState, oldState} = event;

    event.preventDefault();
    popover.show();
  });
});


/**
 * Generics handler to set anchor link as selected.
 *
 * Examples: TOC, tabs....
 */
window.addEventListener("DOMContentLoaded", () => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const id = entry.target.getAttribute("id");
      const anchor = document.querySelector(`nav li a[href="#${id}"]`);
      if (!anchor) {
        return;
      }

      if (entry.intersectionRatio > 0) {
        anchor.setAttribute("aria-selected", true);
      } else {
        anchor.setAttribute("aria-selected", false);
      }
    });
  });

  $$("[id]").forEach((target) => observer.observe(target));
});
