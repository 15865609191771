const people = $$(".person");
people.forEach(person => {
  const button = person.querySelector("button.open-dialog");
  const close = person.querySelector("button.close-dialog");
  const dialog = person.querySelector("dialog");

  if (button) {
    button.addEventListener("click", () => {
      if ($("body.samadmin")) {
        dialog.show();
      } else {
        dialog.showModal();
      }
    });
  
    close.addEventListener("click", () => {
      dialog.close();
    });
  }
});
