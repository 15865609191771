const documentElement = document.documentElement;
const $siteHeader = $(`header[role="banner"]`);
let siteHeaderHeight = 0;

// Toggle classes on document based on scrolling states.
const scroll = {
  top: window.pageYOffset || documentElement.scrollTop,
  direction: "",
}

function openSubnav(parent) {
  const button = $("button", parent);

  parent?.classList.add("open");
  button?.setAttribute(`aria-expanded`, `true`);
}

function closeSubnav(parent) {
  const button = $("button", parent);

  parent?.classList.remove("open");
  button?.setAttribute(`aria-expanded`, `false`);
}

function closeSubnavs() {
  const openMenus = $$("header[role='banner'] nav > ul > .isParent.open");
  openMenus.forEach(function(menu) {
    closeSubnav(menu);
  })
}

function toggleSubnav(navItem) {
  let siblings = Array.prototype.filter.call(navItem.parentNode.children, function(child){
    return child !== navItem;
  });

  if (navItem.classList.contains(`open`)) {
    closeSubnav(navItem);
  } else {
    openSubnav(navItem);
    siblings.forEach(sibling => {
      closeSubnav(sibling);
    })
  }
}

// Toggles the "stuck" state of site header based on scroll.
window.addEventListener("scroll", function() {
  const scrollTop = window.pageYOffset || documentElement.scrollTop;
  if (scrollTop > scroll.top && scroll.direction !== "down") {
    scroll.direction = "down";

    $siteHeader.classList.remove("stuck");
  } else if (scrollTop < scroll.top) {
    scroll.direction = "up";

    if (scrollTop > siteHeaderHeight) {
      $siteHeader.classList.add("stuck");
    } else {
      $siteHeader.classList.remove("stuck");
    }
  }

  scroll.top = scrollTop <= 0 ? 0 : scrollTop; // for Mobile or negative scrolling
}, false);

document.addEventListener("DOMContentLoaded", function() {
  // Updates header's height on resize.
  new ResizeObserver(() => {
    siteHeaderHeight = $siteHeader.getBoundingClientRect().height;
    document.documentElement.style.setProperty(
      "--spacing-header-height",
      `${siteHeaderHeight}px`
    );
  }).observe(document.body);

  // Polyfills Popover with ARIA
  $$(`button[popovertarget^="menu-"]`, $siteHeader).forEach((invoker) => {
    const target = invoker.getAttribute("popovertarget");

    // Adds aria attributes to popover invoker when it is not supported.
    if (!("popover" in HTMLElement.prototype)) {
      invoker.setAttribute("aria-haspopup", "true");
      invoker.setAttribute("aria-expanded", "false");
      invoker.setAttribute("aria-controls", target);

      invoker.addEventListener("click", () => {
        invoker.setAttribute("aria-expanded", invoker.getAttribute("aria-expanded") === "true" ? "false" : "true");
      });
    }
  });
});

document.addEventListener('click', function (event) {
  const targets = `
    header .isParent
  `

  const closest = event.target.closest(targets);
  if (closest !== event.target) return;

  const isMobile = window.matchMedia("(max-width: 767px)").matches;
  if (!isMobile) {
    return;
  }

  const $navItem = closest;
  toggleSubnav($navItem);
}, false);

document.addEventListener('click', function (event) {
  const targets = `
    header .isParent > button
  `

  const closest = event.target.closest(targets);
  if (!closest) return;

  const $navItem = closest.parentNode;
  toggleSubnav($navItem);
}, false);
