import { MarkerClusterer } from "@googlemaps/markerclusterer";
import styles from "./styles.json";

const GOOGLE_MAPS_API_KEY = "AIzaSyA2teKQiSyoTadp5zoB_0eazrZVP8n8hLg";

class ClusterMap {
  static init(maps = document.querySelectorAll("gmp-map")) {
    maps.forEach((mapElement) => new ClusterMap(mapElement));
  }

  constructor(mapElement) {
    this.element = mapElement;
    const { innerMap: map } = mapElement;
    const styledMapType = new google.maps.StyledMapType(styles);
    map.mapTypes.set("styled_map", styledMapType);
    map.setMapTypeId("styled_map");

    const markers = mapElement.querySelectorAll("gmp-advanced-marker");

    markers.forEach((marker) => {
      // Lets the marker uses its text content instead.
      marker.content = null;

      marker.addEventListener("gmp-click", () => {
        this.select(marker);
        map.panTo(marker.position);
      });
    });

    new MarkerClusterer({
      markers,
      map,
      renderer: this,
    });
  }

  /**
   * Adds selected markers
   */
  select(...markers) {
    const event = new CustomEvent("gmp-markerschange", {
      detail: markers,
    });
    this.element.dispatchEvent(event);
  }

  /**
   * Renders a cluster as a single marker.
   *
   * Here we clone the first marker in the cluster, assuming that it
   * is an icon, and replace the text content with the count of markers
   * and remove "icon" class if any.
   */
  render({ count, position, markers }, stats, map) {
    // Clones the first marker in the cluster.
    const marker = markers[0].cloneNode(true);
    marker.position = position;
    marker.title = `Cluster of ${count} markers`;

    const content = marker.querySelector("button");
    content.textContent = count;
    content.classList.remove("icon");

    marker.content = content;

    marker.addEventListener("gmp-click", () => {
      this.select(...markers);
    });

    return marker;
  }
}

const maps = document.querySelectorAll("gmp-map");

if (maps.length) {
  (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
    key: GOOGLE_MAPS_API_KEY,
    v: "beta",
    // Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
    // Add other bootstrap parameters as needed, using camel case.
  });

  Promise.all([
    google.maps.importLibrary("maps"),
    google.maps.importLibrary("marker"),
  ]).then(() => {
    maps.forEach((mapElement) => {
      const map = new ClusterMap(mapElement);
      const places = mapElement.nextElementSibling.querySelectorAll("ul>li");

      mapElement.addEventListener("gmp-markerschange", (event) => {
        const markers = event.detail;

        places.forEach((place) => {
          const match = markers.some(({ title}) => title === place.title);
          place.toggleAttribute("hidden", markers.every((marker) => {
            return marker.title !== place.title;
          }));
        });
      });
    });
  });
}

const regionSelector = document.querySelector(".region-selector");
const mediaQuery = window.matchMedia("(max-width: 768px)");

function adjustRegionSelector(){
  if (mediaQuery.matches) {
    regionSelector.removeAttribute("open");
  } else {
    regionSelector.setAttribute("open", "");
  }
}

if (regionSelector) {
  var callResize;
  window.onresize = function(){
    clearTimeout(callResize);
    callResize = setTimeout(adjustRegionSelector, 100);
  };
  adjustRegionSelector();
}
